import { EVENT_HUE_MIN, EVENT_HUE_MAX } from '../const';
import { mapLinearClamped } from './math';

export function getColorForEvent(event, opacity = 1) {
  const { magnitude } = event;
  const hue = mapLinearClamped(
    magnitude,
    9.1,
    7.5,
    EVENT_HUE_MAX,
    EVENT_HUE_MIN
  );
  return `hsl(${hue.toFixed(2)}deg 100% 50% / ${opacity.toFixed(2)})`;
}
