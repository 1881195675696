const EARTH_RADIUS = 6371000;
const EARTH_RADIUS_IN_KM = EARTH_RADIUS / 1000;

export function generateCirclePolygon(center, radius, numSegments = 64) {
  const { lat, lng } = center;

  const coords = [];

  for (let i = 0; i <= numSegments; i++) {
    const angle = (i / numSegments) * 2 * Math.PI;
    const latOffset = (radius * Math.cos(angle)) / EARTH_RADIUS;
    const lngOffset =
      (radius * Math.sin(angle)) /
      (EARTH_RADIUS * Math.cos((lat * Math.PI) / 180));

    const pointLat = lat + (latOffset * 180) / Math.PI;
    const pointLng = lng + (lngOffset * 180) / Math.PI;

    coords.push({
      lat: pointLat,
      lng: pointLng,
      altitude: 100000,
    });
  }

  return coords;
}

export function getDistance(coord1, coord2) {
  const lat1 = (coord1.lat * Math.PI) / 180;
  const lat2 = (coord2.lat * Math.PI) / 180;
  const dLat = lat2 - lat1;
  const dLng = ((coord2.lng - coord1.lng) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) * Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return EARTH_RADIUS_IN_KM * c; // Distance in kilometers
}
