import { useData } from './DataProvider';

import Event from './Event';

export default function MapEvents() {
  const { activeEvents } = useData();
  return activeEvents.map((event) => {
    return <Event key={event.date} event={event} />;
  });
}
