import { EVENT_DURATION } from './const';

export function lerp(start, end, t) {
  return start + (end - start) * t;
}

export function mapLinear(x, a1, a2, b1, b2) {
  return b1 + ((x - a1) * (b2 - b1)) / (a2 - a1);
}

export function getEventDateRange(event) {
  const date = new Date(event.date);
  const half = Math.round(EVENT_DURATION / 2);
  const start = new Date(date.getTime() - half);
  const end = new Date(date.getTime() + half);
  return {
    start,
    end,
  };
}
