import { useState, useEffect, useMemo, useCallback } from 'react';

export function onMount(fn) {
  useEffect(fn, []);
}

export function onUnmount(fn) {
  useEffect(() => fn, []);
}

export function useEvent(options) {
  const handler = useCallback(options.handler, []);
  useEffect(() => {
    options.setup(handler);
    if (options.initial) {
      handler();
    }
    return () => {
      options.destroy?.(handler);
    };
  }, []);
}

// One of the biggest annoyances with hooks is dealing with
// stale props and state outside of the hooks lifecycle, for
// example when attaching custom event listeners. This hook
// simply mutates a memoized object so that the current values
// are always captured and guaranteed to be up to date.
export function useCurrent(data) {
  const captured = useMemo(() => {
    return {};
  }, []);
  for (let [key, val] of Object.entries({ ...captured, ...data })) {
    if (val === undefined) {
      delete captured[key];
    } else {
      captured[key] = val;
    }
  }
  return captured;
}

// Useful for storing data that does not require
// a component to re-render.
export function useMemoized(initial = {}) {
  return useMemo(() => {
    return initial;
  }, []);
}

// Forces an update by setting state to a new object.
export function useUpdate(onUpdate) {
  const [, setState] = useState();

  return useCallback(() => {
    setState({});
    onUpdate?.();
  }, []);
}
