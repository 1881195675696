import React from 'react';
import { useRef, useEffect } from 'react';
import { generateCirclePolygon } from './utils/geometry';
import { getColorForEvent } from './utils/colors';
import { getEventDateRange } from './utils';
import { mapLinear, mapLinearClamped } from './utils/math';

import { useData } from './DataProvider';

const MIN_RADIUS = 500;
const MAX_RADIUS = 800000;

export default function Event(props) {
  const { event } = props;
  const ringRef = useRef();
  const centerRef = useRef();

  const { map, date } = useData();

  useEffect(() => {
    if (!map) {
      return;
    }

    const { start, end } = getEventDateRange(event);
    const pct = mapLinearClamped(
      date.getTime(),
      start.getTime(),
      end.getTime(),
      0,
      100
    );
    updateRing(event, pct);
    updateCenter(event, pct);
  }, [date]);

  function updateRing(event, pct) {
    const { coordinates, magnitude } = event;

    const maxRadius = getMaxRadius(map, event);
    const radius = mapLinear(pct, 0, 100, 0, maxRadius);
    const opacity = mapLinear(radius, maxRadius, 0, 0, 1.5);
    const zIndex = mapLinear(magnitude, 7.5, 9.5, 1, 100);
    const outerCoordinates = generateCirclePolygon(coordinates, radius);
    ringRef.current.outerCoordinates = outerCoordinates;
    ringRef.current.fillColor = getColorForEvent(event, opacity);
    ringRef.current.zIndex = zIndex;
  }

  function updateCenter(event, pct) {
    const { coordinates } = event;
    const offset = Math.abs(mapLinear(pct, 0, 100, -1, 1));
    const opacity = 1 - offset;
    const outerCoordinates = generateCirclePolygon(coordinates, 20000);
    centerRef.current.outerCoordinates = outerCoordinates;
    centerRef.current.fillColor = `rgb(180 0 0 / ${opacity.toFixed(2)}`;
    centerRef.current.zIndex = 1000;
  }

  return (
    <React.Fragment>
      <gmp-polygon-3d
        ref={centerRef}
        extruded
        geodesic
        altitude-mode="clamp-to-ground"
        fill-color="rgba(255, 0, 0, 0.9)"
      />
      <gmp-polygon-3d
        ref={ringRef}
        extruded
        geodesic
        altitude-mode="clamp-to-ground"
        fill-color="rgba(255, 0, 0, 0.9)"
      />
    </React.Fragment>
  );
}

function getMaxRadius(map, event) {
  const { magnitude } = event;
  let radius = mapLinear(magnitude, 7.5, 9.1, MIN_RADIUS, MAX_RADIUS);
  if (map.range > 19000000) {
    radius *= 2;
  }
  return radius;
}
