import { useMemo, useRef, useState, useEffect } from 'react';
import { useDrag } from 'hooks/drag';
import { mapLinear, mapLinearClamped } from './utils/math';
import { useData } from './DataProvider';
import { getColorForEvent } from './utils/colors';

import {
  playIcon,
  stopIcon,
  listIcon,
  pinIcon,
  eggCrackIcon,
  arrowsMinimizeIcon,
  arrowsMaximizeIcon,
} from './icons';

import { TIMELINE_START, TIMELINE_END } from './const';

import './timeline.less';

const docEl = document.documentElement;

export default function Timeline() {
  const ref = useRef();

  const {
    date,
    setDate,
    isPlaying,
    stopAutoPlay,
    startAutoPlay,
    speed,
    events,
    increaseSpeed,
    moveToEvent,
    toggleList,
    togglePlaces,
    showPlaces,
    showFaults,
    showList,
    toggleFaults,
  } = useData();

  const [helper, setHelper] = useState(false);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(!!document.fullscreenElement);
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  const left = mapLinearClamped(date, TIMELINE_START, TIMELINE_END, 0, 100);

  const ticks = useMemo(() => {
    const arr = [];
    const date = new Date(TIMELINE_START);
    const isSmall = window.innerWidth < 600;
    const increment = isSmall ? 10 : 2;

    while (date < TIMELINE_END) {
      arr.push(new Date(date));
      date.setFullYear(date.getFullYear() + increment);
    }
    return arr;
  }, []);

  function renderTicks() {
    return ticks.map((tick) => {
      return <div key={tick} className="timeline-tick" />;
    });
  }

  function renderBiggest() {
    return events
      .filter((event) => {
        return event.magnitude > 8;
      })
      .map((event) => {
        const { place, magnitude } = event;
        const date = new Date(event.date);
        const left = mapLinearClamped(
          date.getTime(),
          TIMELINE_START.getTime(),
          TIMELINE_END.getTime(),
          0,
          100
        );
        const isHovered = event === hoveredEvent;
        const height = mapLinear(magnitude, 8, 9.1, 10, 60);
        const opacity = mapLinear(magnitude, 8, 9.5, 0.05, 0.6);
        const zIndex = isHovered
          ? 1000
          : Math.round(mapLinear(magnitude, 7.5, 9.5, 1, 100));
        const title = `${place} (${magnitude})`;
        return (
          <div
            key={date}
            style={{
              left: `${left.toFixed(2)}%`,
              height: `${height.toFixed(2)}%`,
              background: getColorForEvent(event),
              opacity,
              zIndex,
            }}
            onPointerDown={(evt) => {
              evt.stopPropagation();
            }}
            onPointerEnter={() => {
              setHoveredEvent(event);
            }}
            onPointerLeave={() => {
              setHoveredEvent(null);
            }}
            onClick={() => {
              stopAutoPlay();
              moveToEvent(event, true);
            }}
            className="timeline-big-event"
          >
            <div className="timeline-big-event-popup">{title}</div>
          </div>
        );
      });
  }

  function setDateForPosition(x) {
    const rect = ref.current.getBoundingClientRect();

    const time = mapLinearClamped(
      x,
      rect.left,
      rect.right,
      TIMELINE_START.getTime(),
      TIMELINE_END.getTime()
    );

    setDate(new Date(time));
  }

  const drag = useDrag({
    ref,
    cursor: true,
    onStart() {
      setHelper(true);
      stopAutoPlay();
    },
    onMove(drag) {
      setDateForPosition(drag.viewport.x);
    },
    onEnd() {
      setHelper(false);
    },
    onClick(evt) {
      setDateForPosition(evt.clientX);
    },
  });

  function renderHelper() {
    const classes = ['timeline-helper'];
    if (helper) {
      classes.push('timeline-helper--active');
    }
    return <div className={classes.join(' ')}>{renderDate()}</div>;
  }

  function renderDate() {
    // return date.getFullYear();
    const formatted = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
    }).format(date);
    return formatted;
  }

  function renderAutoPlay() {
    if (isPlaying) {
      return (
        <div className="timeline-play-button" onClick={stopAutoPlay}>
          {stopIcon}
        </div>
      );
    } else {
      return (
        <div className="timeline-play-button" onClick={startAutoPlay}>
          {playIcon}
        </div>
      );
    }
  }
  function renderSpeeds() {
    return (
      <div className="timeline-speeds">
        <div className="timeline-speeds-toggle" onClick={increaseSpeed} />
        {speed}x
      </div>
    );
  }

  function onListClick() {
    toggleList();
  }

  function onPlacesClick() {
    togglePlaces();
  }

  function onFaultsClick() {
    toggleFaults();
  }

  function renderList() {
    const classes = ['timeline-list'];
    if (showList) {
      classes.push('timeline-list--active');
    }
    return (
      <div className={classes.join(' ')} onClick={onListClick}>
        {listIcon}
      </div>
    );
  }

  function renderPlaces() {
    const classes = ['timeline-places'];
    if (showPlaces) {
      classes.push('timeline-places--active');
    }
    return (
      <div className={classes.join(' ')} onClick={onPlacesClick}>
        {pinIcon}
      </div>
    );
  }

  function renderFaultsButton() {
    const classes = ['timeline-faults'];
    if (showFaults) {
      classes.push('timeline-faults--active');
    }
    return (
      <div className={classes.join(' ')} onClick={onFaultsClick}>
        {eggCrackIcon}
      </div>
    );
  }

  function renderFullscreen() {
    const classes = ['timeline-fullscreen'];
    if (!document.fullscreenEnabled) {
      classes.push('timeline-fullscreen--disabled');
    }
    if (isFullscreen) {
      classes.push('timeline-fullscreen--active');
      return (
        <div
          className={classes.join(' ')}
          onClick={() => {
            document.exitFullscreen();
          }}
        >
          {arrowsMinimizeIcon}
        </div>
      );
    } else {
      return (
        <div
          className={classes.join(' ')}
          onClick={() => {
            docEl.requestFullscreen();
          }}
        >
          {arrowsMaximizeIcon}
        </div>
      );
    }
  }

  return (
    <div className="timeline">
      {renderAutoPlay()}
      {renderSpeeds()}
      <div ref={ref} className="timeline-track" {...drag}>
        <div
          className="timeline-slider"
          style={{ left: `${left.toFixed(2)}%` }}
          {...drag}
        >
          {renderHelper()}
        </div>
        <div className="timeline-ticks">{renderTicks()}</div>
        <div className="timeline-biggest">{renderBiggest()}</div>
      </div>
      {renderList()}
      {renderPlaces()}
      {renderFaultsButton()}
      {renderFullscreen()}
    </div>
  );
}
