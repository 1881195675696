import React from 'react';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useCurrent } from './hooks';
import { at } from 'utils/array';
import { animateToObject } from './utils/animation';
import { getEventDateRange } from './utils';

import { DEFAULT_DATE, TIMELINE_START, TIMELINE_END, SPEEDS } from './const';

import './site.less';

const DataContext = React.createContext();

export default function DataProvider(props) {
  const { children } = props;

  const [loading, setLoading] = useState(true);
  const [demoMode, setDemoMode] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showFaults, setShowFaults] = useState(false);
  const [showPlaces, setShowPlaces] = useState(false);
  const [speed, setSpeed] = useState(getInitialSpeed());
  const [atEnd, setAtEnd] = useState(false);
  const [date, setDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [faults, setFaults] = useState([]);

  const data = useCurrent({
    demoMode,
    speed,
    date,
  });

  const activeEvents = useMemo(() => {
    return events.filter((event) => {
      const { start, end } = getEventDateRange(event);
      return date >= start && date <= end;
    });
  }, [date]);

  function getInitialSpeed() {
    let speed = parseInt(localStorage.getItem('speed'));
    if (isNaN(speed)) {
      speed = 4;
    }
    return speed;
  }

  const onAnimationFrame = useCallback(() => {
    const newDate = new Date(data.date);
    newDate.setDate(newDate.getDate() + 1 * data.speed);
    if (newDate < TIMELINE_END) {
      setDate(newDate);
      setAtEnd(false);
      if (data.demoMode) {
        rotateEarth();
      }
      data.frameId = requestAnimationFrame(onAnimationFrame);
    } else {
      setIsPlaying(false);
      setAtEnd(true);
    }
  });

  const rotateEarth = useCallback(() => {
    const { map } = data;
    if (map?.center) {
      let { lat, lng, altitude } = map.center;
      lng += 0.15;
      map.center = {
        lat,
        lng,
        altitude,
      };
    }
  });

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    localStorage.setItem('speed', speed);
  }, [speed]);

  useEffect(() => {
    if (isPlaying) {
      data.frameId = requestAnimationFrame(onAnimationFrame);
    } else {
      cancelAnimationFrame(data.frameId);
    }
  }, [isPlaying]);

  async function load() {
    setEvents(await loadData('events.json'));
    setFaults(await loadData('faults.json'));
    setLoading(false);
    setDate(DEFAULT_DATE);
  }

  function startAutoPlay() {
    if (atEnd) {
      setDate(TIMELINE_START);
    }
    setIsPlaying(true);
  }

  function stopAutoPlay() {
    setIsPlaying(false);
  }

  function increaseSpeed() {
    const index = SPEEDS.indexOf(speed);
    setSpeed(at(SPEEDS, index + 1));
  }

  function decreaseSpeed() {
    const index = SPEEDS.indexOf(speed);
    setSpeed(at(SPEEDS, index - 1));
  }

  function setMap(map) {
    data.map = map;
  }

  function moveToEvent(event, timeline = false) {
    const { map } = data;
    setDemoMode(false);
    stopAutoPlay();
    animateToObject(map, event, {
      timeline,
      currentDate: data.date,
      onTimelineChange(d) {
        setDate(d);
      },
    });
  }

  function toggleList(on = !showList) {
    setShowList(on);
    setShowPlaces(false);
  }

  function togglePlaces(on = !showPlaces) {
    setShowPlaces(on);
    setShowList(false);
  }

  function toggleFaults(on = !showFaults) {
    setShowFaults(on);
  }

  function moveToPlace(place) {
    animateToObject(data.map, place);
    setDemoMode(false);
  }

  return (
    <DataContext.Provider
      value={{
        map: data.map,
        date,
        speed,
        events,
        faults,
        loading,
        setDate,
        setMap,
        isPlaying,
        moveToEvent,
        activeEvents,
        stopAutoPlay,
        startAutoPlay,
        increaseSpeed,
        decreaseSpeed,
        showList,
        showPlaces,
        toggleList,
        moveToPlace,
        togglePlaces,
        setDemoMode,
        showFaults,
        toggleFaults,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

async function loadData(url) {
  const response = await fetch(url);
  return await response.json();
}

export function useData() {
  return useContext(DataContext);
}
