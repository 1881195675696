import Map from './Map';
import Timeline from './Timeline';
import EventList from './EventList';
import EventPlaces from './EventPlaces';
import DataProvider from './DataProvider';

import './site.less';

export default function App() {
  return (
    <DataProvider>
      <Map />
      <Timeline />
      <EventList />
      <EventPlaces />
    </DataProvider>
  );
}
