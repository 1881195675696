import { useRef, useEffect } from 'react';

import { useData } from './DataProvider';

export default function Fault(props) {
  const { lines } = props.fault;

  return lines.map((coords, i) => {
    return <FaultLine key={i} coords={coords} />;
  });
}

function FaultLine(props) {
  const { coords } = props;
  const { map } = useData();

  const ref = useRef();

  useEffect(() => {
    if (!map) {
      return;
    }

    ref.current.coordinates = coords;
  }, []);

  return (
    <gmp-polyline-3d
      ref={ref}
      geodesic
      altitude-mode="clamp-to-ground"
      stroke-color="rgba(180 0 0 / 60%)"
      stroke-width="8"
    />
  );
}
