export function mapLinear(t, a1, a2, b1, b2) {
  return b1 + ((t - a1) * (b2 - b1)) / (a2 - a1);
}

export function mapLinearClamped(t, a1, a2, b1, b2) {
  return clamp(mapLinear(t, a1, a2, b1, b2), b1, b2);
}

function clamp(v, min, max) {
  return Math.min(Math.max(v, min), max);
}
