// Gets an element from the array by index.
// Allows overshooting the edges.
export function at(arr, index) {
  return arr[normalizeIndex(index, arr.length)];
}

export function normalizeIndex(index, max) {
  index %= max;
  if (index < 0) {
    index += max;
  }
  return index;
}
