import { gsap } from 'gsap';
import { mapLinear } from '../utils';
import { getDistance } from './geometry';

let cameraTween;
let timelineTween;

export function animateToObject(map, object, options = {}) {
  const target = {
    ...object,
    ...getCoords(object),
  };

  options = {
    ...options,
    duration: getAnimationDuration(map, object),
  };

  moveCamera(map, target, options);

  if (options.timeline) {
    moveTimeline(target, options);
  }
}

const DEFAULT_RANGE = 3000000;
const DEFAULT_TILT = 20;

export function moveCamera(map, target, options) {
  const { duration } = options;

  let {
    lat,
    lng,
    altitude = 0,
    tilt = DEFAULT_TILT,
    range = DEFAULT_RANGE,
    heading = map.heading,
  } = target;

  const obj = {
    tilt: map.tilt,
    range: map.range,
    heading: map.heading,
    altitude: map.center.altitude,
    lat: map.center.lat,
    lng: map.center.lng,
  };

  heading = closestHeading(obj.heading, heading);
  lng = closestLongitude(obj.lng, target.lng);

  cameraTween?.kill();
  cameraTween = gsap.to(obj, {
    ease: 'power4.inOut',
    lat,
    lng,
    tilt,
    range,
    heading,
    altitude,
    duration,
    onUpdate: () => {
      map.tilt = obj.tilt;
      map.range = obj.range;
      map.heading = obj.heading;
      map.center = {
        lat: obj.lat,
        lng: obj.lng,
        altitude: obj.altitude,
      };
    },
  });
}

export function moveTimeline(event, options) {
  const { currentDate, onTimelineChange, duration } = options;

  const obj = {
    time: currentDate.getTime(),
  };

  const target = {
    time: new Date(event.date).getTime(),
  };

  timelineTween?.kill();
  timelineTween = gsap.to(obj, {
    ease: 'power4.inOut',
    ...target,
    duration,
    onUpdate: () => {
      onTimelineChange(new Date(Math.round(obj.time)));
    },
  });
}

function closestLongitude(origin, target) {
  // Normalize both longitudes to be within -180 to 180
  origin = ((((origin + 180) % 360) + 360) % 360) - 180;
  target = ((((target + 180) % 360) + 360) % 360) - 180;

  // Calculate the direct difference
  let directDiff = target - origin;

  // Calculate the difference if we wrap around the Earth
  let wrapDiff = directDiff > 0 ? directDiff - 360 : directDiff + 360;

  // Return the target longitude that has the smaller absolute difference
  return Math.abs(directDiff) < Math.abs(wrapDiff)
    ? target
    : target + (wrapDiff > 0 ? 360 : -360);
}

function closestHeading(origin, heading1) {
  const heading2 = heading1 > 180 ? heading1 - 360 : heading1 + 360;
  const d1 = Math.abs(heading1 - origin);
  const d2 = Math.abs(heading2 - origin);
  if (d2 < d1) {
    return heading2;
  } else {
    return heading1;
  }
}

function getAnimationDuration(map, object) {
  const distance = getDistance(
    {
      lat: map.center.lat,
      lng: map.center.lng,
    },
    getCoords(object)
  );

  return mapLinear(distance, 0, 8000, 0.5, 2);
}

function getCoords(object) {
  return {
    lat: object.coordinates?.lat || object.lat,
    lng: object.coordinates?.lng || object.lng,
  };
}
