import { useData } from './DataProvider';

import Fault from './Fault';

export default function MapFaults() {
  const { showFaults, faults } = useData();

  if (!showFaults) {
    return null;
  }

  return faults.map((fault) => {
    return <Fault key={fault.name} fault={fault} />;
  });
}
