import { useRef } from 'react';

import { useMemoized, useEvent } from './index';
import Vec2 from 'utils/Vec2';

import './drag.less';

const DRAG_CLASS = 'ui-dragging';
const DRAG_VAR = '--ui-drag-cursor';

// Note that:
// screenX/Y - Relative to the viewport of the root frame.
// clientX/Y - Relative to the viewport of the current frame.
// pageX/Y   - Relative to the scrolled viewport of the current frame.
// offset/Y  - Relative to the edge of the target node. Note that this
//             is different from the `currentTarget` so this will change
//             depending on which element triggered the event.

export function useDrag(options) {
  const data = useMemoized();

  const ref = options.ref || useRef();

  function onPointerDown(evt) {
    // Ignore right clicks
    if (evt.button !== 0) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
    evt.target.setPointerCapture(evt.pointerId);
    data.origin = new Vec2(evt.clientX, evt.clientY);
    setPositions(evt);
    data.events = [];
    data.drag = null;
    data.movement = null;
    data.constraint = null;

    setCursor(true);

    options.onDown?.({
      evt,
      ...data,
    });
  }

  function onPointerMove(evt) {
    if (!evt.target.hasPointerCapture(evt.pointerId)) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();

    if (!data.drag) {
      if (options.constrain && evt.shiftKey) {
        data.constraint = getConstraint(evt);
      }
      options.onStart?.({
        evt,
        ...data,
      });
    }

    const next = new Vec2(evt.clientX, evt.clientY);
    data.drag = next.sub(data.origin);

    // Note here that we're calculating the movement as an
    // offset in clientX/Y instead of using movementX/Y as
    // there are discrepancies between browsers as to the
    // units returned here.
    // https://github.com/w3c/pointerlock/issues/42
    data.movement = next.sub(data.viewport);

    setPositions(evt);
    data.events.push(evt);

    options.onMove?.({
      evt,
      ...data,
    });
  }

  function onPointerUp(evt) {
    if (!evt.target.hasPointerCapture(evt.pointerId)) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();

    setPositions(evt);

    setCursor(false);
    if (!data.drag) {
      options.onClick?.(evt);
    } else {
      options.onEnd?.({
        evt,
        ...data,
      });
    }
    evt.target.releasePointerCapture(evt.pointerId);
  }

  function onPointerCancel(evt) {
    evt.target.releasePointerCapture(evt.pointerId);
  }

  // Constrain

  function getConstraint(evt) {
    const next = new Vec2(evt.clientX, evt.clientY);
    const drag = next.sub(data.origin);
    const ax = Math.abs(drag.x);
    const ay = Math.abs(drag.y);
    if (ax > ay) {
      return 'horizontal';
    } else if (ay > ax) {
      return 'vertical';
    }
  }

  function setCursor(active) {
    if (!options.cursor) {
      return;
    }
    const doc = document.documentElement;
    if (active) {
      doc.classList.add(DRAG_CLASS);
      doc.style.setProperty(DRAG_VAR, 'grabbing');
    } else {
      doc.classList.remove(DRAG_CLASS);
      doc.style.removeProperty(DRAG_VAR);
    }
  }

  function calculateVelocity(final) {
    const { events = [] } = data;

    // Only take recent events within a 100ms window.
    const recent = events.filter((evt) => {
      return final.timeStamp - evt.timeStamp < 100;
    });

    if (recent.length < 2) {
      return new Vec2(0, 0);
    }

    const [first] = recent;

    const dt = (final.timeStamp - first.timeStamp) / 1000;

    if (dt === 0) {
      return new Vec2(0, 0);
    }

    // Get the average of the total motion across
    // recent events to arrive at the final velocity.
    // Avoid movementX/Y for reasons described above.
    const movement = recent.reduce(
      (acc, evt, i) => {
        if (i === 0) {
          return acc;
        }

        const prev = recent[i - 1];
        const dx = evt.clientX - prev.clientX;
        const dy = evt.clientY - prev.clientY;

        return acc.add(new Vec2(dx, dy));
      },
      new Vec2(0, 0)
    );

    return movement.scale(1 / dt);
  }

  function setPositions(evt) {
    const offset = getConstrainOffset(evt);
    data.page = new Vec2(evt.pageX, evt.pageY).sub(offset);
    data.screen = new Vec2(evt.screenX, evt.screenY).sub(offset);
    data.viewport = new Vec2(evt.clientX, evt.clientY).sub(offset);
    data.velocity = calculateVelocity(evt);
  }

  function getConstrainOffset(evt) {
    const { constraint } = data;
    if (constraint === 'vertical') {
      return new Vec2(evt.clientX - data.origin.x, 0);
    } else if (constraint === 'horizontal') {
      return new Vec2(0, evt.clientY - data.origin.y);
    } else {
      return new Vec2(0, 0);
    }
  }

  // Note this needs to be performed outside the React
  // event cycle as the touchstart event needs to be
  // prevented from reaching the document to avoid issues
  // where the page may scroll despite the touch having
  // originated on the element itself.

  useEvent({
    setup(handler) {
      ref.current.addEventListener('touchstart', handler);
    },
    destroy(handler) {
      ref.current?.removeEventListener('touchstart', handler);
    },

    handler(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
  });

  return {
    ref,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerCancel,
    'data-draggable': true,
  };
}
