export const TIMELINE_START = new Date('1900-01-01T00:00:00.000Z');
export const TIMELINE_END = new Date();

export const DEFAULT_DATE = TIMELINE_START;
export const EVENT_DURATION = 365 * 24 * 60 * 60 * 1000;

export const EVENT_HUE_MIN = 120;
export const EVENT_HUE_MAX = 0;

export const SPEEDS = [1, 2, 4, 8, 16];

export const PLACES = {
  Japan: {
    lat: 35.181276894517524,
    lng: 137.15435244019494,
    range: 2665985.0067320466,
    heading: 19.05203453775148,
    altitude: 124.11955986107786,
    tilt: 53.64471077214783,
  },
  Alaska: {
    lat: 56.4359278342547,
    lng: -157.65961686421844,
    range: 4946728.4252393255,
    heading: -21.432741021634918,
    altitude: -167.81799061264397,
    tilt: 17.15859695030262,
  },
  California: {
    lat: 36.97286548599914,
    lng: -117.15201726387377,
    range: 4180439.8765790462,
    heading: 26.113810097324563,
    altitude: 1235.9403377801782,
    tilt: 7.225991131742689,
  },
  'South America': {
    lat: -28.687890957976574,
    lng: -75.7271524657829,
    range: 9659596.66781187,
    heading: 4.463313189596543,
    altitude: -5779.211305354794,
    tilt: 0,
  },
  Indonesia: {
    lat: 0.6696726488556631,
    lng: 103.89912041299299,
    range: 8341839.499912262,
    heading: 15.029425078184374,
    altitude: -2468.9190764457326,
    tilt: 11.837960934516206,
  },
  'Southeast Asia': {
    lat: 1.0945148099032038,
    lng: 118.6962031203072,
    range: 11070439.180698395,
    heading: 12.675220173410894,
    altitude: -2198.663790031587,
    tilt: 0,
  },
  'New Zealand': {
    lat: -37.971461455520426,
    lng: 177.36037819237455,
    range: 4521987.235461473,
    heading: -2.7201876455381377,
    altitude: -561.4942817701234,
    tilt: 35.4712171063346,
  },
};

export const START_POSITION = {
  lat: 22.410491790619137,
  lng: 75.11267952699518,
  range: 12253912.817040684,
  heading: 358.27153264361215,
  altitude: 3633.8063260171934,
  tilt: 0,
};
