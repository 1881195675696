import { useData } from './DataProvider';
import { getColorForEvent } from './utils/colors';

import './event-list.less';

export default function Events() {
  const { showList, activeEvents, moveToEvent, toggleList } = useData();

  const classes = ['event-list'];
  if (showList) {
    classes.push('event-list--active');
  }

  function renderDate(event) {
    return new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(event.date));
  }

  function renderEmpty() {
    if (!activeEvents.length) {
      return <div className="event-list-empty">No Events</div>;
    }
  }

  return (
    <div className={classes.join(' ')}>
      {activeEvents.map((event) => {
        const { place, magnitude } = event;
        const color = getColorForEvent(event);
        return (
          <div
            key={event.date}
            className="event-list-event"
            onClick={() => {
              moveToEvent(event);
              if (window.innerWidth < 900) {
                toggleList(false);
              }
            }}
          >
            <div className="event-list-place">
              <div>{place}</div>
              <div className="event-list-date">{renderDate(event)}</div>
            </div>
            <div style={{ color }} className="event-list-magnitude">
              {magnitude}
            </div>
          </div>
        );
      })}

      {renderEmpty()}
    </div>
  );
}
