export default class Vec2 {
  static scalar(val) {
    return new Vec2(val, val);
  }

  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  add(vec2) {
    return new Vec2(this.x + vec2.x, this.y + vec2.y);
  }

  sub(vec2) {
    return new Vec2(this.x - vec2.x, this.y - vec2.y);
  }

  multiply(vec2) {
    return new Vec2(this.x * vec2.x, this.y * vec2.y);
  }

  scale(scalar) {
    return new Vec2(this.x * scalar, this.y * scalar);
  }

  length() {
    return Math.sqrt(this.x ** 2 + this.y ** 2);
  }

  abs() {
    return new Vec2(Math.abs(this.x), Math.abs(this.y));
  }

  equals(vec2, threshold = 0) {
    const dx = Math.abs(this.x - vec2.x);
    const dy = Math.abs(this.y - vec2.y);
    return dx <= threshold && dy <= threshold;
  }

  isZero(threshold) {
    return this.equals(new Vec2(0, 0), threshold);
  }
}
