import { useEffect, useRef } from 'react';

import MapEvents from './MapEvents';
import MapFaults from './MapFaults';

import { START_POSITION } from './const';

import { useData } from './DataProvider';

export default function Map() {
  const ref = useRef();

  const { setMap, setDemoMode } = useData();

  useEffect(() => {
    setMap(ref.current);
    const map = ref.current;
    window.exportMap ||= () => {
      const obj = {
        lat: map.center.lat,
        lng: map.center.lng,
        range: map.range ?? 0,
        heading: map.heading,
        altitude: map.center.altitude,
        tilt: map.tilt,
      };
      // eslint-disable-next-line
      console.info(obj);
    };
  }, []);

  const { lat, lng, altitude, tilt, heading, range } = START_POSITION;

  function onMapPointerDown() {
    setDemoMode(false);
  }

  return (
    <gmp-map-3d
      onPointerDown={onMapPointerDown}
      ref={ref}
      default-labels-disabled
      center={`${lat},${lng},${altitude}`}
      range={range}
      heading={heading}
      tilt={tilt}
    >
      <MapEvents />
      <MapFaults />
    </gmp-map-3d>
  );
}
