import { useData } from './DataProvider';
import { PLACES } from './const';

import './event-places.less';

export default function Events() {
  const { showPlaces, togglePlaces, moveToPlace } = useData();

  const classes = ['event-places'];
  if (showPlaces) {
    classes.push('event-places--active');
  }

  return (
    <div className={classes.join(' ')}>
      {Object.entries(PLACES).map(([name, place]) => {
        return (
          <div
            key={name}
            className="event-places-place"
            onClick={() => {
              moveToPlace(place);
              togglePlaces(false);
            }}
          >
            {name}
          </div>
        );
      })}
    </div>
  );
}
